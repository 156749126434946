<template>
    <div>
        序列号<el-select v-model="searchObj.serialNumber" @change="() => { currentPage = 1; getList(); }" clearable filterable>
            <el-option v-for="(item, index) in distinctSerialNumber" :key="index" :value="item"></el-option>
        </el-select> 设备数据时间<el-date-picker v-model="searchObj.dataTime" type="datetime" @change="() => { currentPage = 1; getList(); }" placeholder="精确查找">
        </el-date-picker>
        <el-table :data="tableData" v-loading="loading" border style="margin-top: 10px;">
            <el-table-column label="序列号" prop="serialNumber" width="100"></el-table-column>
            <el-table-column label="设备数据时间" prop="dataTime" width="155"></el-table-column>
            <el-table-column label="T1" prop="t1" align="right"></el-table-column>
            <el-table-column label="T2" prop="t2" align="right"></el-table-column>
            <el-table-column label="T3" prop="t3" align="right"></el-table-column>
            <el-table-column label="T4" prop="t4" align="right"></el-table-column>
            <el-table-column label="T5" prop="t5" align="right"></el-table-column>
            <el-table-column label="T6" prop="t6" align="right"></el-table-column>
            <el-table-column label="T7" prop="t7" align="right"></el-table-column>
            <el-table-column label="T8" prop="t8" align="right"></el-table-column>
            <el-table-column label="电量" prop="dianLiang" align="right"></el-table-column>
            <el-table-column label="创建时间" prop="insertTime" width="155"></el-table-column>
        </el-table>
        <el-pagination @current-change="getList" background :total="total" :page-size="pageSize" :current-page.sync="currentPage"></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            searchObj: { dataTime: null, serialNumber: "" },
            loading: false,
            tableData: [],
            pageSize: 10,
            currentPage: 1,
            total: 0,
            distinctSerialNumber: []
        };
    },
    methods: {
        getList() {
            let that = this;
            that.loading = true;
            that.axios
                .post("WXCW03_Result_Fill/GetList", {
                    serialNumber: that.searchObj.serialNumber,
                    dataTime: that.searchObj.dataTime,
                    currentPage: that.currentPage,
                    pageSize: that.pageSize,
                })
                .then((response) => {
                    that.loading = false;
                    that.total = response.data.data.total;
                    that.tableData = response.data.data.data;
                });
        },
        getDistinctSerialNumber() {
            let that = this;
            that.axios.get("WXCW03_Result_Fill/GetDistinctSerialNumber").then((response) => { that.distinctSerialNumber = response.data.data; });
        }
    },
    mounted() {
        let that = this;
        that.getList();
        that.getDistinctSerialNumber();
    },
};
</script>

<style></style>